import React from "react"
import SEO from "../components/seo"
import paw from "../images/pawprint.svg"
import { Link } from "gatsby"


const NotFoundPage = () => (
  <div className="text-center mt-5 pt-5">
    <SEO title="Not found" />
    <img src={paw} className="paw" alt="pawprint communications"/>

    <h1>Woof!</h1>
    <p>That page doesn't exist.</p>
    <p><Link to="/">Go Home</Link></p>
  </div>
)

export default NotFoundPage
